import React from "react";
import { useTranslation } from "react-i18next";

const Testimony = () => {
  const { t, i18n } = useTranslation();
  return (
    <section id="testimony">
      <div class="container">
        <h1 class="testimony-title" dangerouslySetInnerHTML={{ __html: t('testimony.title') }}></h1>
        <div class="testimony">
          <div class="review">
            <div class="review-header">
              <div class="review-header-right">
                <img src={process.env.PUBLIC_URL + "/images/Ilyondon.jpg"} alt="John Doe" />
                <h2>{t('testimony.1.name')}</h2>
              </div>
              <div class="rating">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
            </div>
            <p>{t('testimony.1.text')}</p>
          </div>
          <div class="review">
            <div class="review-header">
              <div class="review-header-right">
                <img src={process.env.PUBLIC_URL + "/images/elie.jpg"} alt="John Doe" />
                <h2>{t('testimony.2.name')}</h2>
              </div>
              <div class="rating">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
            </div>
            <p>{t('testimony.2.text')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimony;
