import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Price() {
  const { t } = useTranslation();
  const [isMonthly, setIsMonthly] = useState(false);

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <section className="price" id="price">
      <div className="container">
        <h3>{t("prices.pricing")}</h3>
        <h2 className="sec-title2 text-center" dangerouslySetInnerHTML={{ __html: t('prices.title') }}></h2>
        <div className="toggle-container">
          <p>{t("prices.monthly")}</p>
          <div className={`toggle-switch ${isMonthly ? 'monthly' : 'yearly'}`} onClick={handleToggle}>
            <div className="toggle-circle"></div>
          </div>
          <p>{t("prices.yearly")}</p>

        </div>
        <p className="subtitle" dangerouslySetInnerHTML={{ __html: t('prices.subtitle') }}></p>
        <div className="price-container">
          <div className="pc-flex">
            <div className="price-card">
              <div className="header">
                <img
                  src={process.env.PUBLIC_URL + "/images/free.svg"}
                  alt={t("prices.free.headerAlt")}
                />
                <div>
                  <p>{t("prices.for")}</p>
                  <h2>{t("prices.free.title")}</h2>
                </div>
              </div>
              <p>{t("prices.free.description")}</p>
              <div className="price">
                <h2>{t("prices.free.price")}</h2>
                <h3>{t("prices.free.priceSufix")}</h3>
              </div>
              <h3 className="include">{t("prices.include")}</h3>
              <ul>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.free.functionalities.0")}
                  />
                  <p>{t("prices.free.functionalities.1")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.free.functionalities.0")}
                  />
                  <p>{t("prices.free.functionalities.2")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.free.functionalities.0")}
                  />
                  <p>{t("prices.free.functionalities.3")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.free.functionalities.0")}
                  />
                  <p>{t("prices.free.functionalities.4")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greyCheck.svg"}
                    alt={t("prices.free.functionalities.0")}
                  />
                  <p>{t("prices.free.functionalities.5")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greyCheck.svg"}
                    alt={t("prices.free.functionalities.0")}
                  />
                  <p>{t("prices.free.functionalities.6")}</p>
                </li>

              </ul>
              <a className="btn hide-mobile"  href="https://controlresell.onelink.me/gxV7/82zpt3xe">
              {t("prices.free.callToAction")}
              </a>
              <a className="btn show-mobile" href="https://controlresell.onelink.me/gxV7/82zpt3xe">
              {t("mobileCallToAction")}
              </a>
            </div>
            <div className="price-card">
              <div className="header">
                <img
                  src={process.env.PUBLIC_URL + "/images/apprentice.svg"}
                  alt={t("prices.apprentice.headerAlt")}
                />
                <div>
                  <p>{t("prices.for")}</p>
                  <h2>{t("prices.apprentice.title")}</h2>
                </div>
              </div>
              <p>{t("prices.apprentice.description")}</p>
              <div className="price">
                <h2>{isMonthly === true ? t("prices.apprentice.priceMonthly") : t("prices.apprentice.priceYearly")}</h2>
                <h3>{t("prices.apprentice.priceSufix")}</h3>
              </div>
              <h3 className="include">{t("prices.include")}</h3>
              <ul>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.apprentice.functionalities.0")}
                  />
                  <p>{t("prices.apprentice.functionalities.1")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.apprentice.functionalities.0")}
                  />
                  <p>{t("prices.apprentice.functionalities.2")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.apprentice.functionalities.0")}
                  />
                  <p>{t("prices.apprentice.functionalities.3")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.apprentice.functionalities.0")}
                  />
                  <p>{t("prices.apprentice.functionalities.4")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.apprentice.functionalities.0")}
                  />
                  <p>{t("prices.apprentice.functionalities.5")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/greenCheck.svg"}
                    alt={t("prices.apprentice.functionalities.0")}
                  />
                  <p>{t("prices.apprentice.functionalities.6")}</p>
                </li>

              </ul>
              <a className="btn"  href="https://controlresell.onelink.me/gxV7/82zpt3xe">
              {t("prices.apprentice.callToAction")}
              </a>
            </div>
            <div className="price-card card-confirmed">
              <div className="header">
                <img
                  src={process.env.PUBLIC_URL + "/images/confirmed.svg"}
                  alt={t("prices.confirmed.headerAlt")}
                />
                <div>
                  <p>{t("prices.for")}</p>
                  <h2>{t("prices.confirmed.title")}</h2>
                </div>
                <p className="popular">{t("prices.confirmed.popular")}</p>
              </div>
              <p>{t("prices.confirmed.description")}</p>
              <div className="price">
              <h2>{isMonthly === true ? t("prices.confirmed.priceMonthly") : t("prices.confirmed.priceYearly")}</h2>
              <h3>{t("prices.confirmed.priceSufix")}</h3>
              </div>
              <h3 className="include">{t("prices.include")}</h3>
              <ul>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/blackCheck.svg"}
                    alt={t("prices.confirmed.functionalities.0")}
                  />
                  <p>{t("prices.confirmed.functionalities.1")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/blackCheck.svg"}
                    alt={t("prices.confirmed.functionalities.0")}
                  />
                  <p>{t("prices.confirmed.functionalities.2")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/blackCheck.svg"}
                    alt={t("prices.confirmed.functionalities.0")}
                  />
                  <p>{t("prices.confirmed.functionalities.3")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/blackCheck.svg"}
                    alt={t("prices.confirmed.functionalities.0")}
                  />
                  <p>{t("prices.confirmed.functionalities.4")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/blackCheck.svg"}
                    alt={t("prices.confirmed.functionalities.0")}
                  />
                  <p>{t("prices.confirmed.functionalities.5")}</p>
                </li>
                <li>
                  <img
                    src={process.env.PUBLIC_URL + "/images/blackCheck.svg"}
                    alt={t("prices.confirmed.functionalities.0")}
                  />
                  <p>{t("prices.confirmed.functionalities.6")}</p>
                </li>

              </ul>
              <a className="btn"  href="https://controlresell.onelink.me/gxV7/82zpt3xe">
                {t("prices.confirmed.callToAction")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Price;
