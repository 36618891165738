import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';

function ResetPassword() {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { token } = useParams(); 

    const validatePasswords = () => {
        if (password.length < 8) {
            setPasswordError(t('resetPassword.errorPasswordLength'));
            return false;
        }
        if (password !== repeatPassword) {
            setPasswordError(t('resetPassword.errorDifferentPassword'));
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validatePasswords()) {
            setPasswordError('');

            // Envoyer une requête PATCH au serveur
            try {
                const response = await fetch('https://api.controlresell.com/reset-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token,
                        password
                    }),
                });

                const data = await response.json();

                if (response.ok) {
                    // Gérer la réussite
                    alert('Mot de passe réinitialisé avec succès, vous allez être redirigé vers la page d\'accueil');
                    // Attendre 2 secondes
                    setTimeout(() => {
                        // Rediriger vers la home page
                        window.location.href = '/';
                    }, 2000);

                } else {
                    // Gérer les erreurs
                    alert(data.message);
                }
            } catch (error) {
                console.error('Une erreur est survenue', error);
            }
        }
    }

    return (
        <div className="form-reset-password-container">
            <h2 className="form-reset-password-title">{t('resetPassword.title')}</h2>
            <form onSubmit={handleSubmit}>
                <input
                    placeholder={t('resetPassword.passwordPlaceholder')}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                />
                <input
                    placeholder={t('resetPassword.repeatPasswordPlaceholder')}
                    id="repeatPassword"
                    name="repeatPassword"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    type="password"
                />
                <button>{t('resetPassword.button')}</button>
                {passwordError ? <p className="form-reset-password-error">{passwordError}</p> : <p className="form-reset-password-error-placeholder"></p>}
            </form>
        </div>
    );
}

export default ResetPassword;
