import './App.css';
import React, { useEffect } from 'react';
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga';
import LandingPage from './screens/LandingPage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Confirmation from './screens/Confirmation';
import Blog from './screens/Blog';
import SinglePost from './screens/SinglePost';
import Modal from './components/modals/modal';
import Team from './screens/Team';
import ResetPasswordPage from './screens/ResetPassword';
// import * as amplitude from '@amplitude/analytics-node';

// amplitude.init('75caf79f4d82db7087c603e05833976f');


const TRACKING_ID = "UA-180304184-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

Sentry.init({
  dsn: "https://009e1e133de14ffb9c9562048163bd71@o4505075675627520.ingest.sentry.io/4505075677986816",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: process.env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  useEffect(() => {
    document.title = "ControlResell";
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);
  return (
    <>
      <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="confirmation" element={<Confirmation />} />
          <Route path="team" element={<Team />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<SinglePost />} />
          <Route path="reset-password/:token" element={<ResetPasswordPage/>} />
          <Route path="*" element={<h1>404</h1>} />
      </Routes>
      <Modal />
    </>
  );
}

export default App;
