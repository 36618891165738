import React, { useEffect, useState } from 'react';
import Navbar from '../components/header/navbar';
import Functionalities from '../components/functionalities/functionalities';
import Price from '../components/prices/price';
import Faq from '../components/faqs/faqs';
import Footer from '../components/footer/footer';
import Hero from '../components/hero/hero';
import ModalPDF from '../components/modals/modalPDF';
import Testimony from '../components/testimony/testimony';
import Join from '../components/join/functionalities';
import Founder from '../components/founder/founder';

const LandingPage = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('pdf') === 'photo') {
            setShowModal(true);
            console.log('show modal')
        }
    }, []);
    return (
        <>
            <div className='landing-page'>
                { showModal && (<ModalPDF visible={showModal} setVisible={setShowModal} />)}
                <Navbar />
                <Hero />
                <Testimony />
                <Functionalities />
                <Join />
                <Founder />
                <Price />
                <Faq />
                <Footer />
            </div>

        </>
    );
};

export default LandingPage;
