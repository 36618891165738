import GhostContentAPI from '@tryghost/content-api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../components/header/navbar';
import Footer from '../components/footer/footer';
import "../oneBlog.css"
import Modal from '../components/modals/modal';

const api = new GhostContentAPI({
    url: 'https://ghost.controlresell.com',
    key: 'd2e6ebc274420de9f2e2a84144',
    version: "v5.0"
});

function SinglePost() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const fetchedPost = await api.posts.read({
                slug: slug,
                include: 'tags,authors'
            });
            setPost(fetchedPost);
            console.log(fetchedPost)
        }
        fetchData();
    }, [slug]);

    if (!post) return null;

    return (
        <>
            
                <Navbar />
                <main className="">

                    <div className="">

                        <article className="">

                            <div className="">

                                {
                                    post.primary_tag ? <Link href={`/tags/${post.primary_tag.slug}`}>
                                        # {post.primary_tag.name}
                                    </Link> : ""
                                }

                            </div>

                            <h1 className="">
                                {post.title}
                            </h1>

                            <p className="">
                                {post.excerpt}
                            </p>

                            <header className="">

                                <address className="">

                                    <div style={{ justifyContent: "space-evenly", alignContent: "center", flexDirection: "row" }}>

                                        <img width={32} height={32} className="" src={post.primary_author.profile_image} alt={post.primary_author.name} style={{ borderRadius: 50 }} />
                                        {
                                            post.primary_author ? <Link href={`/authors/${post.primary_author.slug}`} rel="author" className="">{post.primary_author.name}</Link> : " "
                                        }

                                        {
                                            post.published_at ? <time className="" dateTime={post.published_at} title={new Date(post.published_at).toLocaleDateString()}>
                                                {" " + new Date(post.published_at).toLocaleDateString()}
                                            </time> : ""
                                        }
                                        <p className=""> {post.reading_time} Min Read</p>

                                    </div>

                                </address>

                            </header>

                            <figure>
                                <img className="" width={1000} height={250} src={post.feature_image} alt={post.feature_image_alt} />
                                <figcaption className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.feature_image_caption
                                    }}></figcaption>
                            </figure>

                            <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                            
                        </article>
                        <a className="btn">
                            {t("callToAction")}
                        </a>
                    </div>
                </main>
                <Footer />
                <Modal/>
            

        </>

    );
}

export default SinglePost;