import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";

function Faq() {
  const { t } = useTranslation();

  $(function () {
    $(".q-flex")
      .unbind()
      .on("click", function () {
        if ($(this).siblings(".answer").is(":visible")) {
          $(".answer").slideUp();
        } else {
          $(".answer").slideUp();
          $(this).siblings(".answer").slideDown();
        }
      });
  });

  return (
    <section       className="faq"      id="faq"    >
      <div className="container">
        <div className="w-70 text-center">
          <h2 className="sec-title2">
            <span className="cyan">{t("faq.titlePart1")}</span>{" "}
            {t("faq.titlePart2")}
          </h2>
          <p className="sec-desc2">{t("faq.subtitle")}</p>
        </div>
        <div className="faqs">
          <div className="qa">
            <div className="q-flex">
              <h4 className="ques">{t("faq.1.question")}</h4>
              <span className="signs">
                <img
                  src={process.env.PUBLIC_URL + "/images/plus.png"}
                  alt="Plus"
                  className="plus"
                  height="20"
                />
              </span>
            </div>
            <div className="answer">
              <p>{t("faq.1.answer")}</p>
            </div>
          </div>
          <div className="qa">
            <div className="q-flex">
              <h4 className="ques">{t("faq.2.question")}</h4>
              <span className="signs">
                <img
                  src={process.env.PUBLIC_URL + "/images/plus.png"}
                  alt="Plus"
                  className="plus"
                  height="20"
                />
              </span>
            </div>
            <div className="answer">
              <p>{t("faq.2.answer")}</p>
            </div>
          </div>
          <div className="qa">
            <div className="q-flex">
              <h4 className="ques">{t("faq.3.question")}</h4>
              <span className="signs">
                <img
                  src={process.env.PUBLIC_URL + "/images/plus.png"}
                  alt="Plus"
                  className="plus"
                  height="20"
                />
              </span>
            </div>
            <div className="answer">
              <p>{t("faq.3.answer")}</p>
            </div>
          </div>
          <div className="qa">
            <div className="q-flex">
              <h4 className="ques">{t("faq.4.question")}</h4>
              <span className="signs">
                <img
                  src={process.env.PUBLIC_URL + "/images/plus.png"}
                  alt="Plus"
                  className="plus"
                  height="20"
                />
              </span>
            </div>
            <div className="answer">
              <p>{t("faq.4.answer")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
