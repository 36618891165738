import * as React from "react";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import '../../blog.css';

const PostCard = ({ post }) => {
    const readingTime = readingTimeHelper(post);
    console.log(post)
    return (
        <a href={"/blog/" + post.slug} className="blog-card">
            <div className="blog-card-header">
                <img src={post.feature_image} alt={post.feature_image_alt} />
            </div>
            <div className="blog-card-body">
                {post.tags.map((tag) => {
                    <span className="blog-tag tag-teal">tag</span>
                }
                )}
                <h4>
                    {post.title}
                </h4>
                <p>
                    {post.title }
                </p>
                <div className="blog-user">
                    <img src={post.primary_author.profile_image} alt="user" />
                    <div className="blog-user-info">
                        <h5>{post.primary_author.slug}</h5>
                        <small>{new Date(post.published_at).toLocaleDateString() + " " +post.reading_time + " min read"}</small>
                    </div>
                </div>
            </div>
        </a>
    );
};





export default PostCard;


