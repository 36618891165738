import React, { useState, useRef } from "react";
import $ from 'jquery';
import { useTranslation } from "react-i18next";

function ModalPDF() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const formRef = useRef(null);

    const validateEmail = (email) => {
        // Expression régulière pour vérifier si l'e-mail est bien formé
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regex.test(email);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateEmail(email)) {
            setEmailError('');
            formRef.current.submit();
        } else {
            setEmailError(t('modal.emailError'));
        }
    }

    $(function () {
        $(document).unbind().on('click', function (e) {
            console.log(e.target, "ici");
            if (!(($(e.target).closest(".modal-close-pdf").length > 0) || ($(e.target).closest(".btn").length > 0))) {
                $(".modal-box-pdf").fadeOut();
                setEmailError('');
            }
        });
    })

    return (
        <div className="backdrop-pdf modal-box-pdf">
            <div className="modal modal-close-pdf">
                <div className="image">
                    <img src={process.env.PUBLIC_URL + '/images/modal-img2.png'} alt={t('modal.alt')} />
                </div>
                <div className="content">
                    <h2 className="modal-title">{t('modalPDF.title')}</h2>
                    <p className="modal-desc">{t('modalPDF.text')}</p>
                    <form ref={formRef} method="POST" action="https://b66aac75.sibforms.com/serve/MUIEAPisf_vvWu9nRxa4UUyq3HOp_3nvWWXXBq2-eM3-tn3_ErpnkVpCNtv-F9XLQpWVkdyQJ_gsjrpG5zmp7jJ5xg7XRAd7MPt5zaUxw3PAIOcIpKgMJ0JqRyvUwQs4Qkv2URKv6s_HxZmjIO3d2kqxkdkFVM8nNccOB8P_oyl918SJSxHV_JFWmpZnAu2Oq5UHqZzLneGPp2OP" onSubmit={handleSubmit}>
                        <div>
                            <input
                                placeholder={t('modalPDF.emailPlaceholder')}
                                id="EMAIL"
                                name="EMAIL"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button>{t('modalPDF.button')}</button>
                        </div>
                        {emailError ? <p className="email-error">{emailError}</p> : <p className="error-placeholder"></p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModalPDF;
