import React, { useState, useRef } from "react";
import $ from 'jquery';
import { useTranslation } from "react-i18next";

function Modal() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const formRef = useRef(null);

    const validateEmail = (email) => {
        // Expression régulière pour vérifier si l'e-mail est bien formé
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regex.test(email);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateEmail(email)) {
            setEmailError('');
            formRef.current.submit();
        } else {
            setEmailError(t('modal.emailError'));
        }
    }

    // $(function () {
    //     $('.btn').unbind().on('click', function (e) {
    //         $('.modal-box-beta').fadeIn();
    //         e.preventDefault();
    //     })

    //     $(document).unbind().on('click', function (e) {
    //         console.log(e.target, "ici");
    //         if (!(($(e.target).closest(".modal-beta").length > 0) || ($(e.target).closest(".btn").length > 0))) {
    //             $(".modal-box-beta").fadeOut();
    //             setEmailError('');
    //         }
    //     });
    // })

    return (
        <div className="backdrop modal-box-beta">
            <div className="modal modal-beta">
                <div className="image">
                    <img src={process.env.PUBLIC_URL + '/images/modal-img2.png'} alt={t('modal.alt')} />
                </div>
                <div className="content">
                    <h2 className="modal-title">{t('modal.title')}</h2>
                    <p className="modal-desc">{t('modal.text')}</p>
                    <form ref={formRef} method="POST" action="https://b66aac75.sibforms.com/serve/MUIEAAPsTHo6-SEndLjf-w6Op7iI4ceb0Svnd8fyFb52JoGIg-edIz4yOHJxl3n-_6bufJNRCe4FQvLA93M5Bi8Hy3wcg4DbmgxQd-Nd_ZfJaI8fwS6L1GgQJpVzfp1OP0V6mqSLtSQpxmB-jdfivoRNEMdjjpun87blkSktc1xxehEr_sJ_YwHAx7Spg-evOKLX2ZOaTWTRTlNh" onSubmit={handleSubmit}>
                        <div>
                            <input
                                placeholder={t('modal.emailPlaceholder')}
                                id="EMAIL"
                                name="EMAIL"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button>{t('modal.button')}</button>
                        </div>
                        {emailError ? <p className="email-error">{emailError}</p> : <p className="error-placeholder"></p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Modal;
