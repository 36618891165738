import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";

// import Logo from 'public/images/logo'
const Navbar = () => {
  const { t } = useTranslation();
  $(function () {
    $("#menuIcon").on("click", function () {
      $(".mobile-nav").slideDown();
      $(this).hide();
      $("#closeIcon").fadeIn();
    });
    $("#closeIcon").on("click", function () {
      $(".mobile-nav").slideUp();
      $(this).hide();
      $("#menuIcon").fadeIn();
    });
  });

  return (
    <header
      style={{
        padding: "0px 0 30px",
      }}
    >
      <nav>
        <div className="container">
          <a href="/" className="logo">
            <img
              src={process.env.PUBLIC_URL + "/images/logo.svg"}
              alt={t("header.navbar.altLogo")}
            />
          </a>
          <ul className="navbar-nav hide-mobile">
            <li>
              <a className="nav-link" href="/#functionalities">
                {t("header.navbar.funcionalities")}
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#price">
                {t("header.navbar.prices")}
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#faq">
                {t("header.navbar.faq")}
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#testimony">
                {t("header.navbar.testimony")}
              </a>
            </li>
            <li><a href="/blog" className="nav-link" >{t('header.navbar.blog')}</a></li>
          </ul>
          <ul className="btn-nav hide-mobile">
            <li>
              <a className="btn"  href="https://controlresell.onelink.me/gxV7/82zpt3xe">
                {t("callToAction")}
              </a>
            </li>
          </ul>
          <div className="menu">
            <img
              src={process.env.PUBLIC_URL + "/images/menu.svg"}
              alt="Menu"
              id="menuIcon"
            />
            <img
              src="./images/close.svg"
              alt={t("header.navbar.altMenu")}
              id="closeIcon"
            />
          </div>
          <div className="show-mobile mobile-nav">
            <ul className="navbar-nav">
              <li>
                <a className="nav-link" href="/">
                  {t("header.navbar.home")}
                </a>
              </li>
              <li>
                <a className="nav-link" href="/#functionalities">
                  {t("header.navbar.funcionalities")}
                </a>
              </li>
              <li>
                <a className="nav-link" href="/#price">
                  {t("header.navbar.prices")}
                </a>
              </li>
              <li>
              <a className="nav-link" href="/#faq">
                {t("header.navbar.faq")}
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#testimony">
                {t("header.navbar.testimony")}
              </a>
            </li>
              <li>
                <a href="/blog" className="nav-link" >{t('header.navbar.blog')}</a>
              </li>
            </ul>
            <ul className="btn-nav">
              <li>
                <a className="btn"  href="https://controlresell.onelink.me/gxV7/82zpt3xe">
                  {t("mobileCallToAction")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
