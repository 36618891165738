import React from "react";
import { useTranslation } from "react-i18next";

function Founder() {
  const { t } = useTranslation();

  return (
    <section className="price" id="price">
      <div className="container">
        <div class="founder">
          <h2 dangerouslySetInnerHTML={{ __html: t('founder.title') }}></h2>
          <div class="card">
            <img
              src={`${process.env.PUBLIC_URL}/images/lyes.png`}
              alt={t("founder.alt")}
            />
            <h3 dangerouslySetInnerHTML={{ __html: t('founder.subtitle') }}></h3>
            <p dangerouslySetInnerHTML={{ __html: t('founder.text') }}></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Founder;
