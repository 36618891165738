import React, { useEffect } from 'react';
import Navbar from '../components/header/navbar';
import Footer from '../components/footer/footer';
import Modal from '../components/modals/modal';
import { useTranslation } from 'react-i18next';

const Confirmation = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/';
    }, 5000);
  }, []);
  return (
    <div style={{
      background: `url(${process.env.PUBLIC_URL}/images/hero-bg.png)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
    }}>         <Navbar />
      <div className="container ">
        <div className="hero-sec">
          <div className="hero-left">
            <h1 className="hero-title">{t("confirmation.title")}</h1>
            <p className="hero-desc">{t("confirmation.text")}</p>
            <div className="store-container">
              <div className="stores">
                <a className="store-btn">
                  <img
                    src={process.env.PUBLIC_URL + "/images/appStore.png"}
                    alt="App Store"
                  />
                </a>
                <a className="store-btn">
                  <img
                    src={process.env.PUBLIC_URL + "/images/googlePlay.png"}
                    alt="Google Play"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal />
    </div>
  );
};

export default Confirmation;