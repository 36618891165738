import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="fm-flex">
          <div className="footer-info">
            <a href="/" className="logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt={t("footer.altLogo")}
              />
            </a>
            <p className="ft-desc">{t("footer.copyright")}</p>
          </div>
          {/* <div className="footer-nav">
            <div className="ft-col">
              <ul>
                <li>
                  <a href="/team"  className="nav-link">
                    {t("footer.team")}
                  </a>
                </li>
                <li>
                  <a  className="nav-link">
                    {t("footer.roadmap")}
                  </a>
                </li>
                <li>
                  <a  className="nav-link">
                    {t("footer.affiliate")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="ft-col">
              <ul>
                <li>
                  <a  className="nav-link">
                    {t("footer.terms")}
                  </a>
                </li>
                <li>
                  <a  className="nav-link">
                    {t("footer.privacy")}
                  </a>
                </li>
                <li>
                  <a  className="nav-link">
                    {t("footer.contact")}
                  </a>
                </li>
              </ul>
            </div> 
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
