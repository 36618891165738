import React from "react";
import { useTranslation } from "react-i18next";

const Join = () => {
  const { t, i18n } = useTranslation();

  return (
    <section id="join">
      <div className="container">
        <div class="join">
          <h2 dangerouslySetInnerHTML={{ __html: t('join.title') }}></h2>
          <img
          src={`${process.env.PUBLIC_URL}/images/${t("join.img")}`}
          alt={t("join.alt")}
          />
        </div>
      </div>
    </section>
  );
};

export default Join;
