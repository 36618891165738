import React from 'react';
import Navbar from '../components/header/navbar';
import Footer from '../components/footer/footer';
import ResetPassword from '../components/resetPassword/resetPassword';


const ResetPasswordPage = () => {
    return (
        <>
            <div style={{
                background: `url(${process.env.PUBLIC_URL}/images/hero-bg.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
            }}>
                <Navbar />
                <ResetPassword />
                <Footer />
            </div>

        </>
    );
};

export default ResetPasswordPage;
