import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";

// import Logo from 'public/images/logo'
const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="hero-sec">
        <h1 className="hero-title">{t("header.title")}</h1>
        <p className="hero-desc" dangerouslySetInnerHTML={{ __html: t('header.text') }}></p>
        <a href="#" className="btn hide-mobile">{t("callToAction")}</a>
        <a href="#" className="btn show-mobile">{t("mobileCallToAction")}</a>
      </div>
    </div>
  )
}

export default Hero;