import React, { useEffect, useState } from 'react';
import Navbar from '../components/header/navbar';
import Footer from '../components/footer/footer';
import GhostContentAPI from '@tryghost/content-api';
import PostCard from '../components/blog/PostCard';
import Modal from '../components/modals/modal';

const Blog = () => {
   const [posts, setPosts] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const api = new GhostContentAPI({
      url: 'https://ghost.controlresell.com',
      key: 'd2e6ebc274420de9f2e2a84144',
      version: "v5.0"
   });

   const getPosts = async (page) => {
      const fetchedPost = await api.posts
         .browse({ limit: 10, include: 'tags,authors', page: page })
         .then((posts) => {
            return posts
         })
         .catch((err) => {
            console.error(err);
         });

      setPosts(fetchedPost)
   }

   useEffect(() => {
      getPosts(currentPage)
   }, [currentPage])

   return (
      <>
         <div style={{
            background: `url(${process.env.PUBLIC_URL}/images/hero-bg.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
         }}>
            <Navbar />
            <div className='blog-container'>
               {posts.map((post, key) => {
                  return (
                     <PostCard post={post} key={key} />
                  )
               })}
               {/* <div className='pagination'>
                  {currentPage > 1 && <button onClick={() => setCurrentPage(currentPage - 1)}>Page précédente</button>}
                  {posts.length >= 5 && <button onClick={() => setCurrentPage(currentPage + 1)}>Page suivante</button>}
               </div> */}
            </div>
            <Footer />
            <Modal/>
         </div>
      </>
   );
};

export default Blog;
