import React from 'react';
import '../Team.css'
const TeamMember = ({ image, name, description }) => {
  return (
    <div className="team-member">
      <img src={image} alt={name} />
      <div className="team-member-info">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Team = () => {
  const teamMembers = [
    {
      image: '../../assets/team/lyes.png',
      name: 'Lyes',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    }
  ];

  return (
    <div>
      <h1>Notre équipe</h1>
      {teamMembers.map(member => (
        <TeamMember key={member.name} image={member.image} name={member.name} description={member.description} />
      ))}
    </div>
  );
};

export default Team;
