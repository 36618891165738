import React from "react";
import { useTranslation } from "react-i18next";

const Functionalities = () => {
  const { t, i18n } = useTranslation();

  return (
    <section id="functionalities">
      <div className="container">
        <div class="functionnalities1">
          <h2 dangerouslySetInnerHTML={{ __html: t('functionalities.1.title') }}></h2>
          <img
          src={`${process.env.PUBLIC_URL}/images/${t("functionalities.1.img")}`}
          alt={t("functionalities.1.alt")}
          />
        </div>
        <div class="functionnalities2">
          <h2 dangerouslySetInnerHTML={{ __html: t('functionalities.2.title') }}></h2>
          <img
          src={`${process.env.PUBLIC_URL}/images/${t("functionalities.2.img")}`}
          alt={t("functionalities.2.alt")}
          />
        </div>
        <div class="functionnalities3">
          <h2 dangerouslySetInnerHTML={{ __html: t('functionalities.3.title') }}></h2>
          <img
          src={`${process.env.PUBLIC_URL}/images/${t("functionalities.3.img")}`}
          alt={t("functionalities.3.alt")}
          />
        </div>
      </div>
    </section>
  );
};

export default Functionalities;
